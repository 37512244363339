import React from "react";
import RedesSociales from "./style";
export default function StyledRedesSociales({

}) {
  return (
    <RedesSociales.IconList>
        <RedesSociales.Icon>
            <a href="#">
                <i className="fab fa-facebook-square" />
            </a>
        </RedesSociales.Icon>
        <RedesSociales.Icon>
            <a href="#">
                <i className="fab fa-twitter" />
            </a>
        </RedesSociales.Icon>
        <RedesSociales.Icon>
            <a href="#">
                <i className="fab fa-instagram" />
            </a>
        </RedesSociales.Icon>
        <RedesSociales.Icon>
            <a href="#">
                <i className="fab fa-linkedin" />
            </a>
        </RedesSociales.Icon>
    </RedesSociales.IconList>
  );
}
