import styled from "styled-components/macro";
import { Box, Button } from "~styled";
const Boton = styled(Box)`
  padding: 100px;
  padding-top: 200px;
  border-top: 1px solid rgba(38, 39, 41, 0.1);
`;
Boton.Button = styled(Button)`
  min-width: ${(props) => props.minWith || "auto"};
  height: ${(props) => props.height || "auto"};
  background-image: ${(props) => props.backGroundColor || "#ff5722"};
  border: 2px solid rgba(255, 255, 255, 0.31);
  box-shadow: ${(props) => props.boxShadow || "none"};
  border-radius: ${(props) => props.borderRadius || "500px"};
  color: ${(props) => props.color || "#fff"};
  &:hover {
    color: ${(props) => props.hoverColor || "#fff"};
    background-image: ${(props) => props.hoverBgColor || "#ff5722"};
  }
`;
export default Boton