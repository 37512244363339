import React from "react";
import Contact from "./style";
import SectionTitle from "./Components/SectionTitle";
import { Col, Container, Row } from "react-bootstrap";
import StyledButton from "../components/Botones";
import StyledRedesSociales from "../components/RedesSociales";
export default function ContactSection() {
  return (
    <Contact backgroundColor="#f3f4f6">
      <Container>
        <Row>
          <Col className="col-xl-7 col-lg-7">
            <Contact.Box>
                <SectionTitle
                  subTitle="Contanos"
                  title="¡Envianos un mensaje!"
                  text=""
                  subTitleProps={{ mb: "10px" }}
                  titleProps={{ mb: "10px", as: "h2" }}
                  mb="50px"
                />
            </Contact.Box>
            <Contact.From>
                <form action="./">
                  <Row>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="floatinginput"
                        />
                        <label htmlFor="floatinginput">Tu correo</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="floatinginput2"
                        />
                        <label htmlFor="floatinginput2">
                          Tu número telefónico
                        </label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea3"
                        />
                        <label htmlFor="floatingTextarea3">Tu mensaje</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-12">
                      <Row className="align-items-center mt-3">
                        <div className="col-md-8 col-lg-7 col-md-6 col-xl-8 pt-3">
                          <div className="form-check d-flex align-items-center">
                            <input
                              className="form-check-input bg-white float-none mt-0"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Su dirección de correo electrónico no será
                              publicada.
                            </label>
                          </div>
                        </div>
                        <Col
                          xs="12"
                          className="col-md-4 col-lg-5 col-xl-4 text-md-end pt-3"
                        >
                          <StyledButton
                            text="Enviar mensaje"
                            backGroundColor="linear-gradient(to right, rgb(208, 36, 116), rgb(163, 82, 175), rgb(95, 82, 212))"
                            hoverBgColor="linear-gradient(to right, rgb(95, 82, 212), rgb(163, 82, 175), rgb(208, 36, 116))"
                          ></StyledButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
            </Contact.From>
          </Col>
          <Col xs="12" className="col-xl-5 col-lg-5">
              <Contact.WidgetsBox className="contact-widget-box">
                <Contact.WidgetsBoxTitle as="h2">
                  Ponte en contacto
                </Contact.WidgetsBoxTitle>
                {/* <Contact.WidgetsBoxText as="p">
                therefore always free from <br className="d-none d-xl-block" />{" "}
                repetition, injected humour, or{" "}
                <br className="d-none d-xl-block" /> non-characteristic
              </Contact.WidgetsBoxText> */}
                <Row>
                  <Col xs="12" className="col-lg-12 col-sm-6 active">
                    <Contact.Widgets>
                      <Contact.WidgetsIcon className="active">
                        <i className="fas fa-phone-alt" />
                      </Contact.WidgetsIcon>
                      <Contact.WidgetsBoxBody>
                        <Contact.WidgetsTitle as="h3">
                          Llamanos
                        </Contact.WidgetsTitle>
                        <Contact.WidgetsText as="p">
                          +52 55 1234 5678
                        </Contact.WidgetsText>
                      </Contact.WidgetsBoxBody>
                    </Contact.Widgets>
                  </Col>
                  <Col xs="12" className="col-lg-12 col-sm-6">
                    <Contact.Widgets>
                      <Contact.WidgetsIcon>
                        <i className="fas fa-map-marker-alt" />
                      </Contact.WidgetsIcon>
                      <Contact.WidgetsBoxBody>
                        <Contact.WidgetsTitle as="h3">
                          Visitanos
                        </Contact.WidgetsTitle>
                        <Contact.WidgetsText as="p">
                          Av. Oaxaca 70, <br className="d-block" /> Ciudad de
                          México 06700, México
                        </Contact.WidgetsText>
                      </Contact.WidgetsBoxBody>
                    </Contact.Widgets>
                  </Col>
                  <Col xs="12" className="col-lg-12 col-sm-6">
                    <Contact.Widgets>
                      <Contact.WidgetsIcon>
                        <i className="fas fa-envelope" />
                      </Contact.WidgetsIcon>
                      <Contact.WidgetsBoxBody>
                        <Contact.WidgetsTitle as="h3">
                          Email de contacto
                        </Contact.WidgetsTitle>
                        <Contact.WidgetsText as="p">
                          correo@correo.com
                        </Contact.WidgetsText>
                      </Contact.WidgetsBoxBody>
                    </Contact.Widgets>
                  </Col>
                </Row>
              </Contact.WidgetsBox>
          </Col>
        </Row>
        {/* 
          Iconos de redes sociales 
        */}
          <div
            style={{ width: "100%", display: "flex", justifyContent: "start" }}
          >
            <StyledRedesSociales />
          </div>
      </Container>
    </Contact>
  );
}
