import React from "react";
import Boton from "./style";
export default function StyledButton({
  minWith,
  height,
  backGroundColor,
  boxShadow,
  borderRadius,
  color,
  hoverColor,
  hoverBgColor,
  text,
  href,
  ...rest
}) {
  return (
    <Boton.Button
      minWith={minWith}
      height={height}
      backGroundColor={backGroundColor}
      boxShadow={boxShadow}
      borderRadius={borderRadius}
      color={color}
      hoverColor={hoverColor}
      hoverBgColor={hoverBgColor}
      style={{position:'relative'}}
      {...rest}
    >
      <a href={href} style={{position:'absolute', width:'100%', height:'100%'}} target="_blank"></a>
      {text}
    </Boton.Button>
  );
}
