import styled from 'styled-components/macro';
import { Box } from '~styled';

const RedesSociales = styled(Box)`
`

RedesSociales.IconList = styled(Box).attrs({className:"footer-social-share list-unstyled", as:"ul"})
`
    padding:5px;
    background-image: linear-gradient(to right, rgb(208, 36, 116), rgb(163, 82, 175), rgb(95, 82, 212));
    border-radius: 15px;
    max-width: 350px;
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
`
RedesSociales.Icon = styled(Box).attrs({className:"list-items", as:"li"})
`
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    
    a{
        min-width: 50px;
        min-height: 50px;
        border-radius: 50%;
        color: #262729;
        font-size: 2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.4s;
        &:hover{
            box-shadow: 0 10px 20px rgba(253 52 110, 0.25);
            background-color: rgba(80, 52, 252, 0.9);
            color: #fff;
        }
    }
    &:first-child {
        a:before {
            display: none;
        }
    }
`
export default RedesSociales;